import React from 'react';
import './ProductItem.css';

function ProductItem({ product, isSelected, onSelect }) {
  return (
    <div 
      className={`product-item ${isSelected ? 'selected' : ''}`}
      onClick={onSelect}
    >
      <h3 className="product-name">{product.productName}</h3>
      <div className="product-details">
        <p className="product-price">{product.finalPrice} MAD</p>
        <p className="product-validity">{product.validity} days</p>
      </div>
      {isSelected && (
        <div className="selected-indicator">✓</div>
      )}
    </div>
  );
}

export default ProductItem;