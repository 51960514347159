import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTeletype } from '@fortawesome/free-solid-svg-icons';
import { useState , useEffect} from 'react';
import './Home.css'
import OperationComponent from './components/OperationComponent';
import CircularProgress from '@mui/joy/CircularProgress';

function Home(){
    useEffect(() => {
      fetchOperations();
    },[]);
    const [error, setError] = useState(()=>'');
    const [loading, setLoading] = useState(() => false);
    const [operations, setOperations] = useState(() => []);
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const postData = {
          number: data.get('number')
        };
        if(postData.number && postData.number.toString().length === 10){
            window.location.href = '/offers/' + postData.number;
        } else {
            setError('يجب إدخال رقم مكون من 10 أرقام');
        }
      }
      function fetchOperations(){
        const op = []
        setLoading(true)
        setOperations(op)
        axios.get('https://rechargemoi.ma/backend/api/operations', {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }).then((response) => {
          for (let i = 0; i < response.data.length; i++) {
              op.push({
                price: response.data[i].price,
                name: response.data[i].productName,
                client: response.data[i].client,
                time: new Date(response.data[i].time * 1000).toLocaleString('en-GB', { timeZone: 'UTC' }),
                success: response.data[i].success
              })
          }
          setOperations(op);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false)
        });
      }
    return (
            <div className="container">
              <form method='POST' onSubmit={handleSubmit} className="mt-5">
              <img src="logo.jpeg" alt="logo" id='logo' height={100} width={100} />
                <div className="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                    <FontAwesomeIcon icon={faTeletype} />
                    </span>
                  </div>
                  <input type="number" className="form-control" id="number" name="number" required  placeholder='أدخل الرقم'  />
                </div>
                <div class="d-grid gap-2">
                  <button type="submit" className="btn btn-primary" id='login'>تأكيد</button>
                  <p id="error">{error}</p>
                </div>
              </form>
              <div className="container">
                {!loading ? operations.map((operation, index) => (
                  <OperationComponent key={index} price={operation.price} name={operation.name} client={operation.client} time={operation.time} success={operation.success} />
                )) : <CircularProgress variant="solid" size='sm' color='neutral'/>}
    </div>
            </div>
    )
}

export default Home;