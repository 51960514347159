import './LoginPage.css';
import { React, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock} from '@fortawesome/free-solid-svg-icons';

function LoginPage() {
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  
  if(localStorage.getItem('token')){
    window.location.href = '/';
    return (<div className="App container"></div>)
  }

  function togglePassword() {
    setShowPassword(showPassword => !showPassword);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const postData = {
      email: data.get('email'),
      password: data.get('password')
    };
    axios.post('https://rechargemoi.ma/backend/api/login', postData, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const js = res.data;
      if (res.status === 200) {
        localStorage.setItem('token', js.token);
        window.location.href = '/';
      } else {
        setError(js.message);
        console.log('Error message:', js.message);
      }
    }).catch((err) => {
      setError("البريد الإلكتروني أو كلمة المرور غير صحيحة");
    });
  }

  return (
    <div className="App container" dir="rtl">
        <img src="logo.jpeg" alt="الشعار" id='logo' height={100} width={100} />
        <h2 className="login-title">تسجيل الدخول</h2>
        
      <form method='POST' onSubmit={handleSubmit} className="mt-5">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
          </div>
          <input 
            type="email" 
            className="form-control" 
            id="email" 
            name="email" 
            placeholder="البريد الإلكتروني"
            required 
          />
        </div>
        
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faLock} />
            </span>
          </div>
          <input 
            type={showPassword ? "text" : "password"}  
            className="form-control" 
            id="password" 
            name="password" 
            placeholder="كلمة المرور"
            required 
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i 
                className={showPassword ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"} 
                onClick={togglePassword}
                title={showPassword ? "إخفاء كلمة المرور" : "إظهار كلمة المرور"}
              ></i>
            </span>
          </div>
        </div>
        
        <div className="d-grid gap-2">
          <button type="submit" className="btn btn-primary" id='login'>تسجيل الدخول</button>
          {error && <p id="error" className="error-message">{error}</p>}
        </div>
      </form>
    </div>
  );
}

export default LoginPage;