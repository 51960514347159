import React from 'react';
import ProductItem from './ProductItem';
import './Category.css';

function Category({ 
  category, 
  isExpanded, 
  onToggleExpand, 
  selectedProductId, 
  onSelectProduct 
}) {
  const handleToggle = () => {
    onToggleExpand(category.catId);
  };

  return (
    <div className={`category ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="category-header" onClick={handleToggle}>
        <h2>{category.catName}</h2>
        <div className="category-controls">
          <span className="picto">{category.picto}</span>
          <span className="expand-icon">{isExpanded ? '▼' : '►'}</span>
        </div>
      </div>
      
      {isExpanded && (
        <div className="products-list">
          {category.products.map((product, index) => (
            <ProductItem 
              key={`${product.productId}-${index}`} 
              product={product}
              isSelected={selectedProductId === `${product.productId}-${index}`}
              onSelect={() => onSelectProduct(`${product.productId}-${index}`, product, category.catId)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Category;