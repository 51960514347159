import React from 'react';
import './Spinner.css';

function Spinner({ overlay = false }) {
  if (overlay) {
    return (
      <div className="loading-overlay">
        <div className="progress-indicator">
          <div className="progress-circle"></div>
          <div className="progress-circle"></div>
          <div className="progress-circle"></div>
        </div>
        <p className="loading-text">Loading offers...</p>
      </div>
    );
  }
  
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <p className="loading-text">Loading offers...</p>
    </div>
  );
}

export default Spinner;