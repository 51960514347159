import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LoginPage from './LoginPage';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route , Routes } from 'react-router-dom';
import Home from './Home';
import ProtectedRoute from './ProtectedRoute';
import NotFound from './NotFound';
import Offers from './OffersPage';
import PaymentPage from './PaymentPage';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter >
     <Routes>
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/" element={<ProtectedRoute/>} >
        <Route index element={<Home/>} />
        <Route path="offers/:number" element={<Offers/>} />
        <Route path="/payment" element={<PaymentPage/>} />
        </Route>
        <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
