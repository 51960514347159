import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PaymentPage.css';
import Spinner from './components/Spinner';

function PaymentPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [error, setError] = useState(null);
    const [paymentAttempted, setPaymentAttempted] = useState(false);
    
    // Extract payment data from location state
    const { product, phoneNumber } = location.state || {};
    
    useEffect(() => {
        // If no product data, redirect back to home
        if (!product || !phoneNumber) {
            navigate('/');
            return;
        }
        
        // Generate a transaction key based on product ID and phone number
        const transactionKey = `payment_${product.productId}_${phoneNumber}`;
        
        // Check if this payment has already been processed
        const existingTransaction = sessionStorage.getItem(transactionKey);
        
        if (existingTransaction) {
            // Parse the stored transaction data
            const transactionData = JSON.parse(existingTransaction);
            
            // Set the stored payment status
            setPaymentStatus(transactionData.status);
            if (transactionData.status === 'failed') {
                setError(transactionData.error || 'فشلت عملية الدفع سابقًا');
            }
            
            // Mark that payment was already attempted
            setPaymentAttempted(true);
            
            console.log('تمت محاولة الدفع مسبقًا:', transactionData);
        } else {
            // This is a new payment - process it
            processPayment(transactionKey);
        }
    }, []);
    
    const processPayment = async (transactionKey) => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');

            // Form the API URL with query parameters
            const paymentUrl = `https://rechargemoi.ma/backend/api/payment?productId=${product.productId}&productName=${product.productName}&client=${phoneNumber}&finalPrice=${product.finalPrice}`;
            
            // Make the payment request
            const response = await axios.post(paymentUrl, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            
            console.log('استجابة الدفع:', response.data);
            
            // Check if payment was successful
            if (response.data == "1") {
                setPaymentStatus('success');
                
                // Store transaction data in session storage
                sessionStorage.setItem(transactionKey, JSON.stringify({
                    status: 'success',
                    timestamp: new Date().toISOString()
                }));
            } else {
                setPaymentStatus('failed');
                const errorMessage = 'تعذر معالجة الدفع. يرجى المحاولة مرة أخرى.';
                setError(errorMessage);
                
                // Store transaction data in session storage
                sessionStorage.setItem(transactionKey, JSON.stringify({
                    status: 'failed',
                    error: errorMessage,
                    timestamp: new Date().toISOString()
                }));
            }
            
            setPaymentAttempted(true);
            setLoading(false);
        } catch (err) {
            console.error('خطأ في الدفع:', err);
            setPaymentStatus('failed');
            const errorMessage = 'حدث خطأ أثناء معالجة الدفع.';
            setError(errorMessage);
            
            // Store transaction data in session storage
            if (transactionKey) {
                sessionStorage.setItem(transactionKey, JSON.stringify({
                    status: 'failed',
                    error: errorMessage,
                    timestamp: new Date().toISOString()
                }));
            }
            
            setPaymentAttempted(true);
            setLoading(false);
        }
    };
    
    const handleGoBack = () => {
        navigate(-1);
    };
    
    const handleGoToHome = () => {
        navigate('/');
    };
    
    const handleTryAgain = () => {
        // Generate a transaction key
        const transactionKey = `payment_${product.productId}_${phoneNumber}`;
        
        // Remove the previous transaction record to allow a retry
        sessionStorage.removeItem(transactionKey);
        
        // Process the payment again
        processPayment(transactionKey);
    };
    
    if (loading) {
        return <Spinner overlay={true} textContent="جاري معالجة الدفع..." />;
    }
    
    // If page is reopened but payment wasn't attempted yet
    if (!paymentAttempted && !loading && !paymentStatus) {
        return (
            <div className="payment-container" dir="rtl">
                <div className="payment-card">
                    <h2>صفحة الدفع</h2>
                    <div className="payment-warning">
                        <div className="warning-icon">⚠️</div>
                        <h3>تحذير من تكرار الدفع</h3>
                        <p>لقد أعدت فتح صفحة الدفع. لتجنب الرسوم المزدوجة، تم إيقاف معالجة الدفع مؤقتًا.</p>
                        <div className="button-group">
                            <button 
                                className="payment-button try-again-button"
                                onClick={handleTryAgain}
                            >
                                متابعة الدفع
                            </button>
                            <button 
                                className="payment-button back-button"
                                onClick={handleGoBack}
                            >
                                رجوع
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    return (
        <div className="payment-container" dir="rtl">
            <div className="payment-card">
                <h2>معالجة الدفع</h2>
                
                {paymentStatus === 'success' && (
                    <div className="payment-success">
                        <div className="success-icon">✓</div>
                        <h3>تم الدفع بنجاح!</h3>
                        <p>تم اكتمال عملية الشحن للرقم {phoneNumber}.</p>
                        <p className="product-info">
                            {product.productName} - {product.finalPrice} درهم
                        </p>
                        <button 
                            className="payment-button back-button"
                            onClick={handleGoToHome}
                        >
                            العودة إلى الصفحة الرئيسية
                        </button>
                    </div>
                )}
                
                {paymentStatus === 'failed' && (
                    <div className="payment-failed">
                        <div className="failed-icon">✕</div>
                        <h3>فشلت عملية الدفع</h3>
                        <p>{error}</p>
                        <div className="button-group">
                            <button 
                                className="payment-button try-again-button"
                                onClick={handleTryAgain}
                            >
                                حاول مرة أخرى
                            </button>
                            <button 
                                className="payment-button back-button"
                                onClick={handleGoBack}
                            >
                                رجوع
                            </button>
                        </div>
                    </div>
                )}
                
                {!paymentStatus && (
                    <div className="payment-initializing">
                        <p>جاري بدء عملية الدفع...</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PaymentPage;