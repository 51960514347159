import { Navigate, Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import "./PR.css";
import CircularProgress from '@mui/joy/CircularProgress';
import 'react-circular-progressbar/dist/styles.css';
function getBalance(setBalance) {
  axios.get('https://rechargemoi.ma/backend/api/user', {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  })
    .then((response) => {
      if (response.status === 200) {
        setBalance(response.data.balance);
      }
    }).catch((error) => {
      localStorage.removeItem('token');
        window.location.href = '/login';
    });
}

const ProtectedRoute = () => {
  let auth = { token: localStorage.getItem('token') };
  let [balance, setBalance] = useState('');
  useEffect(() => {
    if (auth.token) {
      getBalance(setBalance);
    }
  }, [auth.token]);
  console.log(<Outlet />);
  return (
    auth.token ? (
      <>
        <nav>
          {balance != '' ? <h1>{balance}</h1> : <CircularProgress variant="solid" size='sm' color='neutral'/>}
          <div id="icons">
            <i className="fas fa-refresh" onClick={() => {
              window.location.reload();
            }}></i>
            <i id="logout" className="fas fa-sign-out-alt" onClick={() => {
              localStorage.removeItem('token');
              window.location.href = '/login';
            }}></i>
          </div>
        </nav>
        <Outlet />
        <footer ><div>.</div></footer>
      </>
    ) : (
      <Navigate to='/login' />
    )
  );
};

export default ProtectedRoute;