export class Product {
  constructor(productId, productName, finalPrice, validity) {
    this.productId = productId;
    this.productName = productName;
    this.finalPrice = finalPrice;
    this.validity = validity;
  }
}

export class Category {
  constructor(catId, catName, picto, products = []) {
    this.catId = catId;
    this.catName = catName;
    this.picto = picto;
    this.products = products;
  }
}

// Factory functions
export const createProduct = (productId, productName, finalPrice, validity) => {
  return new Product(productId, productName, finalPrice, validity);
};

export const createCategory = (catId, catName, picto, products = []) => {
  return new Category(catId, catName, picto, products);
};