import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './OffersPage.css';
import { useParams, useNavigate } from 'react-router-dom';
import { Category as CategoryModel, Product } from './models/models';
import Category from './components/Category';
import Spinner from './components/Spinner';

function Offers() {
    let { number } = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    // State variables for expanded category and selected product
    const [expandedCategoryId, setExpandedCategoryId] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    
    if (number) {
        number = "212" + number.slice(1);
    }
    
    // Handle category expansion
    const handleToggleExpand = (categoryId) => {
        setExpandedCategoryId(prevId => prevId === categoryId ? null : categoryId);
    };
    
    // Handle product selection
    const handleSelectProduct = (productId, product, categoryId) => {
        setSelectedProductId(prevId => prevId === productId ? null : productId);
        setSelectedProduct(prevProduct => prevProduct?.productId === product.productId ? null : product);
        
        // Ensure the category containing the selected product is expanded
        if (expandedCategoryId !== categoryId) {
            setExpandedCategoryId(categoryId);
        }
    };
    
    // Handle purchase button click
    const handlePurchase = () => {
        if (!selectedProduct) return;
        
        // Create a formatted message with all product details
        const productDetails = `
🎁 تفاصيل المنتج 🎁

الاسم: ${selectedProduct.productName}
السعر: ${selectedProduct.finalPrice} درهم
الصلاحية: ${selectedProduct.validity} أيام

سيتم شحن الرقم: ${number}

هل تريد متابعة عملية الشراء؟
        `;
        
        // Show confirmation alert
        if(window.confirm(productDetails)) {
            // Navigate to payment page with product details
            navigate('/payment', { 
                state: { 
                    product: selectedProduct,
                    phoneNumber: number
                } 
            });
        }
    };

    useEffect(() => {
        console.log("OffersPage mounted with number:", number);
        const fetchOffers = async () => {
            try {
                setLoading(true);
                
                // Configure headers for Inwi API
                const headers = {
                    'Accept': '*/*',
                    'Accept-Language': 'fr-FR,fr;q=0.9,en-US;q=0.8,en;q=0.7',
                    'Connection': 'keep-alive',
                    'Origin': 'https://inwi.ma',
                    'Referer': 'https://inwi.ma/',
                    'Sec-Fetch-Dest': 'empty',
                    'Sec-Fetch-Mode': 'cors',
                    'Sec-Fetch-Site': 'same-site',
                    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/134.0.0.0 Safari/537.36',
                    'sdata': 'eyJjaGFubmVsIjoid2ViIiwiYXBwbGljYXRpb25fb3JpZ2luIjoibXlpbndpIiwidXVpZCI6ImUyMGI1MDgxLTc4MGMtNDQwNS1hZjE1LTNlNmRkN2MzOGYxNyIsImxhbmd1YWdlIjoiZnIiLCJhcHBWZXJzaW9uIjoxfQ==',
                    'sec-ch-ua': '"Chromium";v="134", "Not:A-Brand";v="24", "Google Chrome";v="134"',
                    'sec-ch-ua-mobile': '?0',
                    'sec-ch-ua-platform': '"Windows"',
                };

                // Call the Inwi API
                const response = await axios.get(`https://api.inwi.ma/api/v1/ms-catalog/recharge/${number}`, {
                    params: { gzip_controle: 'true' },
                    headers: headers
                });
                
                // Process the data to match our models
                if (response.data) {
                    console.log("API response:", response.data);
                    const parsedCategories = parseApiData(response.data);
                    setCategories(parsedCategories);
                    
                    // // Auto-expand the first category if available
                    // if (parsedCategories.length > 0) {
                    //     setExpandedCategoryId(parsedCategories[0].catId);
                    // }
                } else {
                    setCategories([]);
                }
                
                setLoading(false);
            } catch (err) {
                console.error("Error fetching offers:", err);
                setError("Failed to load offers. Please try again.");
                setLoading(false);
            }
        };

        fetchOffers();
    }, [number]);

    // Parse API data into our Category and Product models
    const parseApiData = (data) => {
        // Create a map to store categories by ID to avoid duplicates
        const categoriesMap = new Map();
        const validPictos = ["Dh", "*1", "*2", "*3", "*4", "*6"];
        
        // Process each item in the response
        data.forEach(item => {
            // Create a product object
            const product = new Product(
                item.productId,
                item.productName,
                item.pricing?.finalPrice || 0,
                item.ProductSpecs?.validity || 0
            );
            
            // Process categories for this product
            item.categories.forEach(categoryResponse => {
                // Only include categories with valid picto values
                if (validPictos.includes(categoryResponse.picto_text)) {
                    // Get existing category or create new one
                    let category = categoriesMap.get(categoryResponse.catId);
                    
                    if (!category) {
                        category = new CategoryModel(
                            categoryResponse.catId,
                            categoryResponse.catName,
                            categoryResponse.picto_text,
                            []
                        );
                        categoriesMap.set(categoryResponse.catId, category);
                    }
                    
                    // Clone the product to avoid modifying the original
                    const productForCategory = { ...product };
                    
                    // Modify product name to include the category's picto
                    productForCategory.productName = category.picto;
                    
                    // Add the modified product to the category
                    category.products.push(productForCategory);
                }
            });
        });
        console.log("Categories map:", categoriesMap);
        
        // Convert map to array and sort by picto
        return Array.from(categoriesMap.values())
            .sort((a, b) => a.picto.localeCompare(b.picto));
    };

    if (loading) return <Spinner overlay={true} textContent="جاري التحميل..." />;
    if (error) return <div className="container" dir="rtl">خطأ: {error}</div>;

    return (
        <div className="container" dir="rtl">
            <h1>العروض للرقم {number}</h1>
            {categories.length === 0 ? (
                <p>لا توجد عروض متاحة لهذا الرقم.</p>
            ) : (
                <>
                    <div className="categories-list">
                        {categories.map(category => (
                            <Category 
                                key={category.catId} 
                                category={category}
                                isExpanded={expandedCategoryId === category.catId}
                                onToggleExpand={handleToggleExpand}
                                selectedProductId={selectedProductId}
                                onSelectProduct={handleSelectProduct}
                            />
                        ))}
                    </div>
                    
                    {selectedProduct && (
                        <div className="selected-product-summary">
                            <h3>العرض المختار</h3>
                            <div className="summary-details">
                                <p><strong>النوع:</strong> {selectedProduct.productName}</p>
                                <p><strong>السعر:</strong> {selectedProduct.finalPrice} درهم</p>
                                <p><strong>الصلاحية:</strong> {selectedProduct.validity} أيام</p>
                                
                                <div className="product-details-list">
                                    <h4>تفاصيل العرض</h4>
                                    <div className="detail-item">
                                        <span className="detail-label">رقم المنتج:</span>
                                        <span>{selectedProduct.productId}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="detail-label">الرقم المراد شحنه:</span>
                                        <span>{number}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="detail-label">طريقة الدفع:</span>
                                        <span>بطاقة الائتمان / الدفع عبر الهاتف</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="detail-label">التفعيل:</span>
                                        <span>فوري</span>
                                    </div>
                                </div>
                            </div>
                            <button 
                                className="purchase-button"
                                onClick={handlePurchase}
                            >
                                شراء هذا العرض
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Offers;