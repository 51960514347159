import React from 'react';
import './OperationComponent.css'; // Assurez-vous de créer ce fichier pour les styles personnalisés
function OperationComponent({ price, name, client, time, success }) {
    let circleColor = success == '1' ? 'green' : 'red';
  return (
    <div className="operation-component container">
      <div className="myrow">
          <span className="price text-primary">{price}</span>
          
          <div style={{display: 'flex', flexDirection: 'row'}}>
          <span className="name text-dark">{name}</span>
          <span className="circle-container"
                      style={{ backgroundColor: circleColor }}></span>
          </div>
      </div>
      <div className="myrow" >
          <span className="client text-dark">{client}</span>
          <span className="time text-dark">{time}</span>
      </div>
    </div>
  );
}

export default OperationComponent;